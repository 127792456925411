import React, {useState} from "react";
import './SubscriptionListItem.scss';
import ToolTip from "./ToolTip";
import { isMobile } from "react-device-detect";

const SubscriptionListItem = props => {
    const [showInfoBlock, setShowInfoBlock] = useState(false)

    return (
        <li>
            <input type="checkbox"
                   disabled={props.item.disabled}
                   name={props.name}
                   id={props.name}
                   checked={props.item.checked}
                   onChange={e => props.handleInputChange(e)} />
            <label htmlFor={props.name}>
                {props.item.label}
            </label>
            {props.item.info ?
                 <span aria-hidden="true"
                    className={'info'}
                    onMouseEnter={isMobile ? () => (false) : e => {setShowInfoBlock(true)}}
                    onMouseLeave={isMobile ? () => (false) : e => {setShowInfoBlock(false)}}
                    onMouseDown={e => {setShowInfoBlock(!showInfoBlock)}}
                >i<ToolTip showInfoBlock={showInfoBlock} info={props.item.info} /></span>
                : ''}
        </li>
    )
}

export default SubscriptionListItem
