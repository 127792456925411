import React from "react";
import './Tooltip.scss';

const ToolTip = props => {
    return (
        <div style={{display: props.showInfoBlock ? 'block' : 'none'}} className={'tooltip'}>
            {props.info}
        </div>
    )
}

export default ToolTip;

