import * as React from "react";
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StaticImage } from "gatsby-plugin-image"

import "swiper/scss";
import "swiper/css/navigation"
import './TestimonialsWebsiteKoppeling.scss'

const Testimonials = () => (
    <div className="content">
        <div className="cols">
            <div className="col-xs-100 align-center">
                <h2>Wat vinden anderen?</h2>
            </div>
        </div>

        <Swiper
            modules={[Navigation, Autoplay]}
            navigation={true}
            className="mySwiper"
            loop={true}
            autoplay={{ delay: 10000 }}
        >
            <SwiperSlide>
                <div className="cols">
                    <div className="col-xs-16 align-center" />
                    <div className="col-xs-66 align-center">
                        <StaticImage src="../images/logo-nynke-ggz.png" alt="Het logo van Nynke GGZ Noord" className="logo"/>
                        <h3 className="quote">"We hoeven niet meer handmatig de aanmeldingen in te voeren in het elektronische patiëntendossier. We hoeven niet meer alle gegevens uit te vragen, maar kunnen ingaan op de relevante gegevens."</h3>
                        <p className="small">- Liza Smid, Nynke | GGZ Noord</p>
                    </div>
                    <div className="col-xs-16 align-center" />
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="cols">
                    <div className="col-xs-16 align-center" />
                    <div className="col-xs-66 align-center">
                        <StaticImage src="../images/logo-connected-together.png" alt="Het logo van Connected Together" className="logo"/>
                        <h3 className="quote">"Client is per direct aangemaakt in het EPD."</h3>
                        <p className="small">- Jannet Doeleman, Connected Together</p>
                    </div>
                    <div className="col-xs-16 align-center" />
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="cols">
                    <div className="col-xs-16 align-center" />
                    <div className="col-xs-66 align-center">
                        <StaticImage src="../images/logo-nynke-ggz.png" alt="Het logo van Nynke GGZ Noord" className="logo"/>
                        <h3 className="quote">"De gegevens zijn vaak correct en compleet aangezien de patiënt hier de verantwoordelijkheid voor heeft. Het draagt in de behandeling bij omdat de patiënt vanaf het begin af aan al een actieve rol heeft in het behandelproces."</h3>
                        <p className="small">- Liza Smid, Nynke | GGZ Noord</p>
                    </div>
                    <div className="col-xs-16 align-center" />
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
)

export default Testimonials
