import * as React from "react"
import Navbar from "../../components/Navbar";
import Helmet from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"
import TitleSection from "../../components/TitleSection";
import "./website-koppeling-aanmelding.scss";
import SubscriptionSelection from "../../components/SubscriptionSelection";
import {Link} from "gatsby";
import Footer from "../../components/Footer";
import TestimonialsWebsiteKoppeling from "../../components/TestimonialsWebsiteKoppeling";

// markup
const WebsiteKoppelingAanmelding = () => {
    return (
        <>
            <Helmet>
                <title>Careweb koppeling | Website koppeling 'Aanmelding'</title>
                <meta name="description" content="Koppel jullie website aan Careweb en verwerk de aanmelding automatisch in Careweb."/>
                <meta property="og:title" content="Careweb koppeling | Website koppeling 'Aanmelding'"/>
                <meta property="og:url" content="https://meneerjansen-ict.nl/careweb/website-koppeling-aanmelding"/>
                <meta property="og:description" content="Meer tijd voor zorg met een koppeling tussen jullie website en Careweb."/>
                <meta property="og:image" content="https://meneerjansen-ict.nl/images/logo.png"/>
                <body className="page-website-integration" />
            </Helmet>

            <Navbar/>

            <main>

                <article className="bg-circle">
                    <TitleSection title="Website Koppeling" subtitle="Informatie vanaf je website direct in Careweb"/>

                    <div className="content">
                        <div className="cols">

                            <div className="col-xs-100 col-md-50 col-lg-33">
                                <StaticImage src="../../images/pexels-andrea-piacquadio-3807747.jpg" alt="Afbeelding van een brug"/>
                            </div>

                            <div className="col-xs-100 col-md-50 col-lg-66 ">
                                <section className="text-based">
                                    <p>
                                        Verwerk aanmeldingen op je eigen website direct in Careweb.
                                    </p>
                                    <p>
                                        Als alle aanmeldingen via de koppeling verlopen, weet je zeker je alle cliëntgegevens compleet hebt in het EPD.
                                    </p>
                                    <section className="text-based">
                                        <p>
                                            Bekijk hier een <Link to="/demo/website-koppeling-aanmelding">voorbeeld aanmeldformulier</Link>.
                                        </p>
                                    </section>

                                    <h3 style={{ marginTop: '2em'}}>Voordelen</h3>
                                    <ul className="check">
                                        <li>Bespaar tijd</li>
                                        <li>Informatie foutloos en compleet in het EPD</li>
                                        <li>Direct veilig en AVG-proof opgeslagen</li>
                                    </ul>

                                    <h3>Mogelijkheden</h3>
                                    <ul className="check">
                                        <li>Uitvragen van NAW gegevens</li>
                                        <li>Relevante gegevens van de cliënt en de hulpvraag</li>
                                        <li>Direct vastleggen van verschillende toestemmingen <br/><span className="li-small">(Akkoord op de tarieven, algemene voorwaarden, het privacy statement etc.)</span></li>
                                        <li>Verwijsbrief mee laten sturen</li>
                                        <li>Automatische mail naar de cliënt</li>
                                    </ul>

                                </section>

                            </div>

                        </div>
                    </div>
                </article>


                <article className="bg-grey">
                    <TestimonialsWebsiteKoppeling />
                </article>

                <article>
                    <div className="content">

                        <section className="text-based" style={{ marginBottom: '0'}}>
                            <div className="cols">
                                <div className="col-xs-100">
                                    <h2 className="sm-fsize-70">Wat kost het het?</h2>

                                    <p>
                                        Een website koppeling is mogelijk vanaf € 1.099,- en maandelijks € 31,45 excl. btw.
                                    </p>

                                    <p>
                                        De prijs  is afhankelijk van het aantal hulpverlener licenties in Careweb.
                                    </p>

                                    <p>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th> </th>
                                                <th className="align-right">1</th>
                                                <th className="align-right">2-10</th>
                                                <th className="align-right">10-25</th>
                                                <th className="align-right">25-100</th>
                                                <th className="align-right">100+</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th>Abonnement</th>
                                                <td className="align-right">€ 31,45</td>
                                                <td className="align-right">€ 31,45</td>
                                                <td className="align-right">€ 47,20</td>
                                                <td className="align-right">€ 74,50</td>
                                                <td className="align-right">€ 125,00</td>
                                            </tr>
                                            <tr>
                                                <th>Implementatie</th>
                                                <td className="align-right">€ 1.099,00</td>
                                                <td className="align-right">€ 1.499,00</td>
                                                <td className="align-right">€ 2.199,00</td>
                                                <td className="align-right">€ 2.990,00</td>
                                                <td className="align-right">€ 2.990,00</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </p>

                                    <p style={{fontSize: '10pt'}}>
                                        De genoemde bedragen zijn exclusief de wettelijk verplichte btw. De prijs voor de implementatie is 'vanaf'. Zie onderstaande tabel voor extra mogelijkheden.
                                    </p>
                                </div>
                            </div>
                        </section>



                        <section className="text-based" style={{ marginBottom: '0'}}>
                            <div className="cols">
                                <div className="col-xs-100">
                                    <h2 className="sm-fsize-70">Extra mogelijkheden</h2>

                                    <SubscriptionSelection />

                                    <p style={{fontSize: '10pt'}}>
                                        * Prijzen obv een organisatie met 1 hulpverlener licentie. Zie hierboven voor meer informatie.
                                    </p>
                                </div>
                            </div>
                        </section>








                        <section className="text-based" style={{ marginBottom: '0'}}>
                            <div className="cols">
                                <div className="col-xs-100">
                                    <h2 className="sm-fsize-70">Keuze facturatietermijn</h2>

                                    <p>
                                        Standaard worden de abonnementskosten jaarlijks gefactureerd, voorafgaand aan een nieuw abonnementsjaar.<br/>
                                        Het is ook mogelijk om per maand of kwartaal te betalen. Voorbeeld op basis van 1-10 hulpverlener licenties:
                                    </p>

                                    <p>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th> </th>
                                                <th className="align-right">Kosten per maand</th>
                                                <th className="align-right">Kosten per jaar</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th>Jaarlijks</th>
                                                <td className="align-right">€ 31,45</td>
                                                <td className="align-right">€ 377,40</td>
                                            </tr>
                                            <tr>
                                                <th>Kwartaal</th>
                                                <td className="align-right">€ 42,00</td>
                                                <td className="align-right">€ 504,00</td>
                                            </tr>
                                            <tr>
                                                <th>Maandelijks</th>
                                                <td className="align-right">€ 50,00</td>
                                                <td className="align-right">€ 600,00</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </p>

                                    <p style={{fontSize: '10pt'}}>
                                        De genoemde bedragen zijn exclusief de wettelijk verplichte btw.
                                    </p>
                                </div>
                            </div>
                        </section>

                    </div>
                </article>

                <article>
                    <div className="content">

                        <section className="text-based">
                            <div className="cols">
                                <div className="col-xs-100 faq">
                                    <h2>Veel gestelde vragen</h2>

                                    <h3 id="email_not_safe">Wij doen nu aanmelding via e-mail. Dat is toch ook veilig?</h3>
                                    <p>
                                        Gegevens die verstuurd worden via de website koppeling gaan over een beveiligde, versleutelde verbinding direct het EPD in. Hiermee zijn de gegevens veilig en AVG-proof opgeslagen.<br/>
                                        Dit is niet altijd het geval wanneer je persoonsgegevens vanaf je website via e-mail zou versturen. Zonder een aantal beveiligingseisen wordt dit door de AVG gezien als 'gebrek aan beveiliging'
                                        (zie  <a target="_blank" rel="noreferrer" href="https://www.autoriteitpersoonsgegevens.nl/nl/onderwerpen/beveiliging/beveiliging-van-persoonsgegevens#hoe-kan-ik-veilig-persoonsgegevens-via-e-mail-versturen-6172">Autoriteit Persoonsgegevens</a>).
                                    </p>

                                    <h3>Waarom zijn er maandelijkse kosten verbonden aan een website koppeling?</h3>
                                    <p>
                                        Dat heeft een aantal redenen.
                                    </p>

                                    <ul>
                                        <li>Met het maandelijkse bedrag betaal je voor het gebruik en onderhoud van de koppelsoftware en server</li>
                                        <li>De software wordt continue up-to-date gehouden</li>
                                        <li>De server wordt doorlopend gemonitord voor o.a. beveiliging en werking</li>
                                        <li>Voor de hosting van de koppeling worden maandelijks kosten gemaakt die worden doorberekend</li>
                                    </ul>

                                    <h3>Kan ik ook meer dan één formulier koppelen?</h3>
                                    <p>
                                        Ja dat kan, je kunt zoveel formulieren koppelen als gewenst. Op ieder volgend formulier geldt een korting van 50%.
                                    </p>


                                    <h3>Moet er voor de koppeling software worden geïnstalleerd op onze server(s)?</h3>
                                    <p>
                                        Nee, er hoeft niets geïnstalleerd te worden. <br/>Er moet een link en een stukje HTML opgenomen worden op je eigen website.
                                        Hiervoor ontvang je een handleiding. Enige kennis van HTML is wel vereist. Mocht je dit niet hebben, dan kunnen wij de inbouw verzorgen.
                                    </p>

                                </div>
                            </div>

                        </section>

                    </div>
                </article>

            </main>

            <Footer />
        </>

    )
}

export default WebsiteKoppelingAanmelding;
