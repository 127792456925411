import React, {useEffect, useState} from 'react';
import ButtonLink from "./ButtonLink";
import './SubscriptionSelection.scss';
import SubscriptionListItem from "./SubscriptionListItem";

const SubscriptionSelection = () => {
    const [subscriptionData, setSubscriptionData] = useState({
        connectingServer: {
            price: 31.45,
            checked: true,
            type: 'monthly',
            disabled: true,
            label: 'Gebruik van de koppelserver',
        },
        formSecurity: {
            price: 0,
            checked: true,
            type: 'monthly',
            disabled: true,
            label: 'Beveiliging tegen spam formulieren in Careweb',
        },
        carewebSignalingEmail: {
            price: 0,
            checked: true,
            type: 'monthly',
            disabled: true,
            label: 'E-mail notificatie als een aanmelding is ontvangen',
        },
        clientSignalingEmail: {
            price: 7.5,
            checked: false,
            type: 'monthly',
            disabled: false,
            label: 'E-mail naar cliënt na aanmelding',
        },
        documentUpload: {
            price: 7.5,
            checked: false,
            type: 'monthly',
            disabled: false,
            label: 'Mogelijkheid om verwijsbrief mee te sturen',
            info: 'Let op; het is niet mogelijk om documenten direct in Careweb te uploaden. Informeer naar de mogelijkheden.',
        },
        designForm: {
            price: 0,
            checked: true,
            type: 'initial',
            disabled: true,
            label: 'Afstemming over de inhoud van het formulier',
        },
        functioningConnection: {
            price: 1099,
            checked: true,
            type: 'initial',
            disabled: true,
            label: 'Inregelen van de koppeling',
        },
        technicalChangesCareweb: {
            price: 0,
            checked: true,
            type: 'initial',
            disabled: true,
            label: 'Aanvraag technische veranderingen bij Careweb',
        },
        formCreation: {
            price: 349,
            checked: false,
            type: 'initial',
            disabled: false,
            label: 'Aanmaken van formulier en elementen  in Careweb',
            info: 'Binnen Careweb moet een formulier aangemaakt worden. Mogelijk doe je dit zelf of hebben jullie een service partner. Wij kunnen dit ook verzorgen, vink dan deze optie aan.',
        },
        dynamicForm: {
            price: 399,
            checked: false,
            type: 'initial',
            disabled: false,
            label: 'Dynamische formulier elementen',
            info: 'Dynamische formulier elementen zijn vragen die pas verschijnen wanneer een andere vraag is ingevuld of een bepaalde waarde heeft.',
        },
        formImplementation: {
            price: 199,
            checked: false,
            type: 'initial',
            disabled: false,
            label: 'Inbouw op jullie website',
            info: 'De inbouw bestaat uit het opnemen van een stuk HTML en inlinken van Javascript obv onze documentatie. Echter, wij kunnen dit ook verzorgen.',
        },
        formPrivacy: {
            price: 350,
            checked: false,
            type: 'initial',
            disabled: false,
            label: 'Akkoord cliënt opslaan in apart formulier',
            info: 'Wanneer de aanmeldgegevens worden opgeslagen op een formulier dat later nog wordt aangepast door hulpverleners, is een uitgevraagd akkoord niet goed geborgd. Het is dan aan te raden om deze vragen op een apart formulier op te slaan.',
        },
        carewebSignaling: {
            price: 399,
            checked: false,
            type: 'initial',
            disabled: false,
            label: 'Signalering in Careweb bij ontvangst van een aanmelding',
        },
    });

    const [monthlyPrice, setMonthlyPrice] = useState();
    const [initialPrice, setInitialPrice] = useState();
    const [btw, setBtw] = useState(false);

    const handleInputChange = (event) => {
        const { name, checked } = event.target;

        setSubscriptionData(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                checked,
            }
        }));

        setPricing();
    }

    const setPricing = () => {
        let monthlyTotal = 0;
        let initialTotal = 0;

        for (const [key, item] of Object.entries(subscriptionData)) {
            if (!item.checked) continue;

            if (item.type === 'monthly') {
                monthlyTotal += item.price;
            }

            if (item.type === 'initial') {
                initialTotal += item.price;
            }
        }

        setMonthlyPrice(monthlyTotal);
        setInitialPrice(initialTotal);
    }

    useEffect(() => {
        setPricing();
    })

    return (
        <div className="cols cols-without-margin">
            <div className="col-md-100 prod-spec-col">
                <h3 className="align-center">Kies je samenstelling</h3>

                <hr/>

                <div className="cols subscription">
                    <div className="col-md-50">
                        <h4 className="align-center">Abonnement</h4>

                        <ul className="monthlyList">
                            {Object.keys(subscriptionData).map((item, index) => {
                                if (subscriptionData[item].type === 'monthly') {
                                    return <SubscriptionListItem key={index} item={subscriptionData[item]} name={item} handleInputChange={handleInputChange} />
                                }

                                return null;
                            })}
                        </ul>
                    </div>
                </div>

                <div className="cols subscription">
                    <div className="col-md-50">
                        <h4 className="align-center">Implementatie</h4>
                        <ul className="initialList">
                            {Object.keys(subscriptionData).map((item, index) => {
                                if (subscriptionData[item].type === 'initial') {
                                    return <SubscriptionListItem key={index} item={subscriptionData[item]} name={item} handleInputChange={handleInputChange} />
                                }

                                return null;
                            })}
                        </ul>
                    </div>
                </div>
                <hr/>


                <section>
                    <div className="pricing-h pricing-h2">
                                        <span className="pre-heading">
                                            Abonnement *
                                        </span>
                        {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(btw ? monthlyPrice * 1.21 : monthlyPrice)} <span className="price-small">/pm</span>
                    </div>
                    <div className="pricing-h pricing-h2">
                        <span className="pre-heading">Eenmalig</span>
                        {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(btw ? initialPrice * 1.21 : initialPrice)}
                    </div>
                    <div className="pricing-h pricing-h2">
                        <input type="checkbox" id="btw" name="btw" checked={btw} onChange={e => setBtw(e.target.checked)} /><label htmlFor="btw">Incl. BTW</label>
                    </div>
                </section>

                <hr/>

                <section className="ButtonContainer align-center">
                    <ButtonLink label="Vraag aan" to="/contact"/>
                </section>
            </div>
        </div>
    );
}

export default SubscriptionSelection;
